.input {
  width: 100%;
  padding: 0.8em;
  margin: 0.6em 0;
  border: 2px solid var(--colors-light);
  border-radius: 0.6em;
  color: var(--colors-text);
  background-color: var(--colors-super-duper-light);
}

.input:focus {
  outline: none;
  border-color: var(--colors-link);
}

.input.textarea {
  margin-bottom: 0;
  line-height: 1.5;
  min-height: calc(5 * 1.5rem + 1.5em); /* avoid layout shift when textarea-autosize loads */
  resize: vertical;
}

.input.invalid {
  border-color: var(--colors-error);
}

.errorMessage {
  font-size: 0.9em;
  color: var(--colors-error);
}

.actionRow {
  display: flex;
  align-items: center;
  margin-top: 0.6em;
  min-height: 3.75em;
}

.submitButton {
  flex-shrink: 0;
  height: 3.25em;
  padding: 1em 1.25em;
  margin-right: 1.5em;
  border: 0;
  border-radius: 0.6em;
  cursor: pointer;
  user-select: none;
  font-weight: 500;
  color: var(--colors-text);
  background-color: var(--colors-kinda-light);
}

.submitButton:hover,
.submitButton:focus-visible {
  color: var(--colors-super-duper-light);
  background-color: var(--colors-link);
}

.result {
  font-weight: 600;
  line-height: 1.5;
}

.result.success {
  color: var(--colors-success);
}

.result.error {
  color: var(--colors-error);
}

.resultIcon {
  display: inline;
  width: 1.3em;
  height: 1.3em;
  vertical-align: -0.3em;
}
